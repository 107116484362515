<template>
  <div class="base-container-first index__sections">
    <div class="index__header">
      <div class="index__header-wrapper">
        <h1 class="index__header-content typography__body1">
          {{ t("pages.index.header.subtitle") }}

          <span
            class="index__header-title typography__headline2"
            v-html="t('pages.index.header.title')"
          />
        </h1>

        <BaseModalButton
          class="base-button-big base-button-first index_button typography__title2"
          :modal="MODAL.AuthRegistration"
        >
          {{ t("common.tryFree") }}
        </BaseModalButton>
      </div>

      <BasePicture
        alt=""
        class="index__header-image"
        src="/images/about-main.png"
      />
    </div>

    <ul class="index__advantages">
      <li
        v-for="(bonus, bonusIndex) in BONUSES"
        :key="bonusIndex"
        class="index__advantages-item"
      >
        <span class="advantages__item-icon">
          <BaseIcon2
            :id="bonus.icon.name"
            color="var(--orange-1)"
            :height="bonus.icon.height"
            width="28"
          />
        </span>

        <h4
          class="advantages__item-title typography__headline3"
          v-html="t(bonus.title)"
        />

        <p
          class="advantages__item-description typography__body1"
          v-html="t(bonus.description)"
        />
      </li>
    </ul>
  </div>

  <div class="index__container">
    <section class="base-container-first index__wrapper">
      <div class="index__business">
        <IndexTitle :description="t('pages.index.features.subtitle')">
          <template #title>
            <div v-html="t('pages.index.features.title')" />
          </template>
        </IndexTitle>

        <SharedPageBusiness
          :business-list="BUSINESSES"
          hide-button
          hide-title
        />
      </div>

      <div class="index__wrapper-cards">
        <IndexSliderBig :card-list="CARDS_FIRST" />

        <BaseModalButton
          class="base-button-big base-button-first index_button typography__title2"
          :modal="MODAL.AuthRegistration"
        >
          {{ t("common.becomeClient") }}
        </BaseModalButton>
      </div>

      <IndexTitle :description="t('pages.index.approach.subtitle')">
        <template #title>
          <div v-html="t('pages.index.approach.title')" />
        </template>
      </IndexTitle>

      <IndexSlider :card-list="CARDS_SECOND" />

      <div class="index__try">
        <IndexTitle :description="t('pages.index.quickBuy.subtitle')">
          <template #title>
            <div v-html="t('pages.index.quickBuy.title')" />
          </template>

          <template #description>
            <div class="about-title__description">
              {{ t("pages.index.quickBuy.platforms") }}

              <div class="title__description-socials">
                <BaseIcon2
                  id="icon-telegram-full"
                  height="32"
                  sprite="socials"
                  width="32"
                />

                <BaseIcon2
                  id="icon-instagram-full"
                  height="32"
                  sprite="socials"
                  width="32"
                />

                <BaseIcon2
                  id="icon-snapchat-full"
                  height="32"
                  sprite="socials"
                  width="32"
                />
              </div>
            </div>
          </template>
        </IndexTitle>

        <BaseModalButton
          class="base-button-big base-button-first index_button typography__title2"
          :modal="MODAL.AuthRegistration"
        >
          {{ t("common.tryFree") }}
        </BaseModalButton>
      </div>
    </section>
  </div>

  <ul
    id="examples"
    class="base-container-first index__examples"
  >
    <li
      v-for="(section, sectionIndex) in SECTION_CARDS"
      :key="sectionIndex"
    >
      <SharedPageSectionCard
        :card="section"
        :reverse="section.reverse"
      />
    </li>
  </ul>

  <div class="index__container">
    <section class="base-container-first steps__wrapper">
      <IndexTitle :description="t('pages.index.integration.subtitle')">
        <template #title>
          <div v-html="t('pages.index.integration.title')" />
        </template>
      </IndexTitle>

      <IndexSlider
        auto-height
        :card-list="STEPS"
        class="index__cards-slider"
        :is-cards="false"
      />

      <ul class="index__steps">
        <li
          v-for="(step, stepIndex) in STEPS"
          :key="stepIndex"
        >
          <IndexStep
            :step
            :step-number="stepIndex + 1"
          />
        </li>
      </ul>

      <BaseModalButton
        class="base-button-big base-button-first index_button typography__title2"
        :modal="MODAL.AuthRegistration"
      >
        {{ t("common.becomeClient") }}
      </BaseModalButton>
    </section>
  </div>
</template>

<script setup lang="ts">
const { t } = useI18n();

const BONUSES = [
  {
    description: t("pages.index.bonuses.items.earnings.description"),
    icon: {
      height: "16",
      name: "icon-arrow-graphics",
    },
    title: t("pages.index.bonuses.items.earnings.title"),
  },
  {
    description: t("pages.index.bonuses.items.visibility.description"),
    icon: {
      height: "13",
      name: "icon-infinity",
    },
    title: t("pages.index.bonuses.items.visibility.title"),
  },
  {
    description: t("pages.index.bonuses.items.efficiency.description"),
    icon: {
      height: "28",
      name: "icon-wand",
    },
    title: t("pages.index.bonuses.items.efficiency.title"),
  },
];

const CARDS_FIRST = [
  {
    description: t("pages.index.cards.first.onlineRecord.description"),
    href: "/features/online-record",
    image: "index-card-1",
    title: t("pages.index.cards.first.onlineRecord.title"),
  },
  {
    description: t("pages.index.cards.first.shop.description"),
    href: "/features/shop",
    image: "index-card-2",
    title: t("pages.index.cards.first.shop.title"),
  },
  {
    description: t("pages.index.cards.first.services.description"),
    href: "/features/services-with-applications",
    image: "index-card-3",
    title: t("pages.index.cards.first.services.title"),
  },
  {
    description: t("pages.index.cards.first.crm.description"),
    href: "/features/crm",
    image: "index-card-4",
    title: t("pages.index.cards.first.crm.title"),
  },
  {
    description: t("pages.index.cards.first.chats.description"),
    href: "/features/chats",
    image: "index-card-5",
    title: t("pages.index.cards.first.chats.title"),
  },
];

const CARDS_SECOND = [
  {
    description: t("pages.index.cards.second.website.description"),
    href: "/features/website",
    image: "website-card-2",
    title: t("pages.index.cards.second.website.title"),
  },
  {
    description: t("pages.index.cards.second.cabinet.description"),
    href: "/features/client-cabinet",
    image: "client-card-2",
    title: t("pages.index.cards.second.cabinet.title"),
  },
  {
    description: t("pages.index.cards.second.bot.description"),
    href: "/features/bot",
    image: "bot-card-2",
    title: t("pages.index.cards.second.bot.title"),
  },
];

const BUSINESSES = [
  t("pages.index.businesses.beautySalon"),
  t("pages.index.businesses.clothingStore"),
  t("pages.index.businesses.lawyer"),
  t("pages.index.businesses.restaurant"),
  t("pages.index.businesses.carWash"),
  t("pages.index.businesses.fitness"),
  t("pages.index.businesses.notary"),
  t("pages.index.businesses.photoStudio"),
  t("pages.index.businesses.billiards"),
  t("pages.index.businesses.yourBusiness"),
];

// TODO: вставить УРЛ на телеграм бота когда он появится
const SECTION_CARDS = [
  {
    description: t("pages.index.sectionCards.barbershop.description"),
    href: "/",
    image: "index-section-card-1",
    reverse: false,
    title: t("pages.index.sectionCards.barbershop.title"),
  },
  {
    description: t("pages.index.sectionCards.clothing.description"),
    href: "/",
    image: "index-section-card-2",
    reverse: true,
    title: t("pages.index.sectionCards.clothing.title"),
  },
  {
    description: t("pages.index.sectionCards.legal.description"),
    href: "/",
    image: "index-section-card-3",
    reverse: false,
    title: t("pages.index.sectionCards.legal.title"),
  },
];

const STEPS = [
  {
    color: "#ffffff",
    description: t("pages.index.steps.needs.description"),
    title: t("pages.index.steps.needs.title"),
  },
  {
    color: "#dadada",
    description: t("pages.index.steps.setup.description"),
    title: t("pages.index.steps.setup.title"),
  },
  {
    color: "#bababa",
    description: t("pages.index.steps.data.description"),
    title: t("pages.index.steps.data.title"),
  },
  {
    color: "#979797",
    description: t("pages.index.steps.instructions.description"),
    title: t("pages.index.steps.instructions.title"),
  },
];

const route = useRoute();
const { openModal } = useModalStore();

onMounted(() => {
  if (route.query.modal) {
    openModal(route.query.modal as string);
  }
});

definePageMeta({
  layout: "default",
  middleware: [
    async (to) => {
      const { tokenState } = useAuthStore();
      const { $env } = useNuxtApp();

      if ($env.isServer && tokenState.value.accessToken && !to.query.modal) {
        return navigateTo("/client/projects");
      }

      if ($env.isClient && to.query.modal) {
        const { removeRefreshToken } = useAuthToken();

        removeRefreshToken();
      }
    },
  ],
});
</script>

<style scoped>
.index__header {
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

@media (--desktop) {
  .index__header {
    flex-direction: row;
    align-items: center;
    column-gap: 40px;
    padding: 0 24px;
  }
}

@media (--laptop) {
  .index__header {
    padding-right: 0;
    padding-left: 0;
  }
}

.index__header-image {
  flex-shrink: 0;
  width: 320px;
  height: 100%;
  margin: 0 -24px;
  padding: 0 24px;
}

@media (--desktop) {
  .index__header-image {
    width: 623px;
    max-width: 100%;
    height: 610px;
    max-height: 100%;
    margin-right: 0;
    margin-left: 0;
    padding-right: 0;
    padding-left: 0;
  }
}

.index__container {
  background-color: var(--white-1);
}

.index__header-wrapper {
  display: flex;
  flex-direction: column;
  row-gap: 24px;
}

@media (--desktop) {
  .index__header-wrapper {
    row-gap: 40px;
    width: 583px;
  }
}

.index__header-content {
  display: flex;
  flex-direction: column;
  row-gap: 12px;
  max-width: 320px;
  font-weight: 400;
}

@media (--desktop) {
  .index__header-content {
    row-gap: 16px;
    max-width: max-content;
    font-size: 18px;
  }

  .index__header-title {
    font-size: 60px;
    line-height: 1.1;
  }
}

.index__advantages {
  display: flex;
  flex-direction: column;
  row-gap: 24px;
  padding: 40px 24px;
  border-radius: 32px;
  background-color: var(--grey-7);
}

@media (--desktop) {
  .index__advantages {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    column-gap: 80px;
    margin: 0 40px;
    padding: 80px;
    border-radius: 50px;
  }
}

.index__advantages-item {
  display: flex;
  flex-direction: column;
  row-gap: 12px;
}

@media (--desktop) {
  .index__advantages-item {
    row-gap: 16px;
  }
}

.advantages__item-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 56px;
  height: 56px;
  border-radius: 50%;
  background-color: var(--white);
}

@media (--desktop) {
  .advantages__item-title {
    max-width: 240px;
    font-weight: 400;
    font-size: 32px;
    line-height: 1.25;
  }
}

.advantages__item-description {
  color: var(--grey-1-5);
}

.index__wrapper {
  display: flex;
  flex-direction: column;
  row-gap: 80px;
  margin: 64px auto 56px;
}

@media (--desktop) {
  .index__wrapper {
    row-gap: 120px;
    margin-top: 96px;
    margin-bottom: 80px;
  }
}

.index__sections {
  display: flex;
  flex-direction: column;
  row-gap: 40px;
  margin: 40px auto 80px;
  padding: 0 24px;
}

@media (--desktop) {
  .index__sections {
    row-gap: 60px;
    margin-top: 0;
    margin-bottom: 120px;
    padding-right: 0;
    padding-left: 0;
  }
}

.index__wrapper-cards {
  display: flex;
  flex-direction: column;
  row-gap: 32px;
}

@media (--desktop) {
  .index__wrapper-cards {
    align-items: center;
    row-gap: 40px;
  }
}

.index_button {
  max-width: max-content;
  padding: 12px 24px;
  font-size: 16px;
  border-radius: 30px;
}

@media (--desktop) {
  .index_button {
    padding: 16px 40px;
  }

  .steps__wrapper .index_button {
    margin: 0 auto;
  }
}

.index__business {
  display: flex;
  flex-direction: column;
  row-gap: 24px;
}

@media (--desktop) {
  .index__business {
    row-gap: 32px;
  }
}

.about-title__description {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  row-gap: 12px;
  column-gap: 24px;
  font-weight: 400;
  font-size: 18px;
}

.index__examples {
  display: flex;
  flex-direction: column;
  row-gap: 40px;
  margin: 80px 0;
  padding: 0 24px;
}

@media (--desktop) {
  .index__examples {
    row-gap: 60px;
    max-width: 1200px;
    margin: 120px auto;
    padding-right: 0;
    padding-left: 0;
  }
}

.title__description-socials {
  display: flex;
  align-items: center;
  column-gap: 24px;
  color: var(--grey-3);
}

@media (--desktop) {
  .index__cards-slider {
    display: none;
  }
}

.steps__wrapper {
  display: flex;
  flex-direction: column;
  row-gap: 40px;
  margin: 64px auto 56px;
}

@media (--desktop) {
  .steps__wrapper {
    row-gap: 80px;
    margin-top: 96px;
    margin-bottom: 96px;
  }
}

.index__steps {
  display: none;
}

@media (--desktop) {
  .index__steps {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    column-gap: 40px;
  }
}

.index__try {
  display: flex;
  flex-direction: column;
  row-gap: 32px;
}

@media (--desktop) {
  .index__try {
    row-gap: 40px;
  }
}

:deep(.index__try .about) {
  row-gap: 16px;
}

@media (--desktop) {
  :deep(.index__try .about) {
    row-gap: 24px;
  }
}
</style>
