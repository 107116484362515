<template>
  <div class="step">
    <span
      class="step__progress"
      :class="`step__progress${stepNumber}`"
      :style="`background: ${step.color}`"
    />

    <dl class="step__body typography__body1">
      <div class="step__title">
        <span class="step__number">
          {{ t("pages.index.steps.step_number", { number: stepNumber }) }}
        </span>

        <dt
          class="typography__headline3"
          v-html="step.title"
        />
      </div>

      <dd
        class="step__text"
        v-html="step.description"
      />
    </dl>
  </div>
</template>

<script setup lang="ts">
interface Props {
  step: Step;
  stepNumber?: number;
}

interface Step {
  color: string;
  description: string;
  title: string;
}

defineProps<Props>();

const { t } = useI18n();
</script>

<style scoped>
.step {
  display: flex;
  flex-direction: column;
  row-gap: 24px;
}

@media (--desktop) {
  .step {
    row-gap: 40px;
  }
}

.step__progress {
  display: block;
  width: 100%;
  height: 16px;
  border-radius: 50px;
}

@media (--desktop) {
  .step__progress {
    width: calc(100% + 50px);
  }

  .step__progress.step__progress4 {
    width: 100%;
  }
}

.step__body {
  display: flex;
  flex-direction: column;
  row-gap: 12px;
}

@media (--desktop) {
  .step__body {
    row-gap: 24px;
  }
}

.step__title {
  display: flex;
  flex-direction: column;
  row-gap: 12px;
}

.step__number {
  display: none;
  color: var(--grey-2);
}

@media (--desktop) {
  .step__number {
    display: block;
  }
}

.step__text {
  margin-top: auto;
  color: var(--grey-1-5);
}
</style>
