<template>
  <div class="slider__container">
    <div class="slider__wrapper">
      <div class="slider__header typography__body1">
        <template v-if="isCards">
          {{ withSeparator([currenSlide, cardList.length], " / ") }}
        </template>

        <template v-else>{{ t("pages.index.steps.step_number", { number: currenSlide }) }}</template>

        <div class="slider__buttons">
          <BaseButton2
            class="slider__button"
            :class="{ disabled: currenSlide === 1 }"
            @click="() => clickPrevSlide()"
          >
            <BaseIcon2
              id="icon-cap"
              class="slider__button-prev"
              height="24"
              width="24"
            />
          </BaseButton2>

          <BaseButton2
            class="slider__button"
            :class="{ disabled: currenSlide === cardList.length }"
            @click="() => clickNextSlide()"
          >
            <BaseIcon2
              id="icon-cap"
              height="24"
              width="24"
            />
          </BaseButton2>
        </div>
      </div>

      <BaseSlider
        allow-touch-move
        :auto-height
        :breakpoints="{
          769: {
            autoHeight,
            slidesPerView: 3,
            spaceBetween: 20,
          },
        }"
        class="ui-slider-primary"
        :slides="cardList"
        :slides-per-view="1"
        :space-between="8"
        @slide-change="(activeIndex) => (currenSlide = activeIndex + 1)"
        @swiper="(slider) => (mainSlider = slider)"
      >
        <template #default="{ slide }">
          <SharedPageCard
            v-if="isCards"
            :card="slide"
            class="slider__card"
          />

          <IndexStep
            v-else
            :step="slide"
          />
        </template>
      </BaseSlider>
    </div>
  </div>
</template>

<script setup lang="ts">
interface Props {
  autoHeight?: boolean;
  cardList: object[];
  isCards?: boolean;
}

withDefaults(defineProps<Props>(), {
  isCards: true,
});

const { t } = useI18n();
const currenSlide = ref(1);
const mainSlider = ref(null);

const clickNextSlide = () => {
  mainSlider.value.slideNext();
};

const clickPrevSlide = () => {
  mainSlider.value.slidePrev();
};
</script>

<style scoped>
.slider__wrapper {
  display: grid;
  row-gap: 16px;
}

.slider__wrapper :deep(.swiper) {
  max-width: 100%;
}

.slider__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: var(--grey-2);
}

@media (--desktop) {
  .slider__header {
    display: none;
  }
}

.slider__buttons {
  display: flex;
  column-gap: 8px;
}

.slider__button {
  padding: 8px;
  color: var(--grey-3);
}

.slider__button.disabled {
  color: var(--grey-4);
}

.slider__button-prev {
  rotate: 180deg;
}

.slider__card {
  background-color: var(--white);
}
</style>
